<template>
  <div class="video-player-wrapper" @contextmenu.prevent=";">
    <videoPlayer
      ref="player"
      class="video-player"
      :options="playerOptions"
      :playsinline="true"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
      @loadeddata="onPlayerLoadedData($event)"
      @ready="onPlayerReady"
      @touchstart="onClick"
      @fullscreenchange="onFullscreenChange"
      @error="onError"
    ></videoPlayer>
    <div v-show="showPlayEndedImage" class="player-end-wrapper" @click="onClickEndedImage">
      <div class="player-end-content"></div>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
import { videoPlayer } from 'vue-video-player/src'

export default {
  name: 'VideoPlayer',
  components: { videoPlayer },
  props: {
    // 视频链接
    videoLink: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      playing: false,
      showPlayEndedImage: false,
      playerOptions: {
        autoplay: false,
        controls: true,
        muted: false,
        language: 'zh-CN',
        fluid: true,
        aspectRatio: '16:9',
        fullscreen: false,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: '',
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          // 播放按钮
          playToggle: true,
          // 分隔符
          timeDivider: true,
          // 总时间
          durationDisplay: true,
          // 是否显示剩余时间
          remainingTimeDisplay: true,
          // 全屏按钮
          fullscreenToggle: true,
          // 播放速率，当前只有html5模式下才支持设置播放速率
          playbackRateMenuButton: false,
          // 音量
          volumePanel: false,
        },
      },
      // 结尾视频是否已经播放
      endVideoPlayed: false
    }
  },
  watch: {
    videoLink(val) {
      this.$refs.player.player.pause()

      this.playerOptions.sources = [{
        type: "video/mp4",
        src: val
      }]

      this.$refs.player.player.load()
    },
    poster(val) {
      this.playerOptions.poster = this.poster
    },
    $route(route) {
      console.log('路由改变')
    }
  },
  created() {
    if (this.poster) {
      this.playerOptions.poster = this.poster
    }

    this.playerOptions.sources.push({
      type: "video/mp4",
      src: this.videoLink
    })
    // this.playerOptions.sources.push({
    //   type: "video/mp4",
    //   src: 'http://ricoh-oss.oss-cn-shenzhen.aliyuncs.com/ricoh/template/video_end.mp4'
    // })
  },
  methods: {
    onPlayerPlay(player) {
      console.log('尝试 play')
      this.playing = true
      this.showPlayEndedImage = false

      if (this.endVideoPlayed) {
        player.controlBar.hide()
      } else {
        player.controlBar.show()
      }
    },
    onPlayerPause() {
      this.playing = false
    },
    onPlayerEnded(player) {
      console.log('播放结束 ', player)
      this.showPlayEndedImage = true
      player.controlBar.hide()

      const isFullscreen = player.isFullscreen()
      console.log('是否全屏: ', isFullscreen)

      if (!this.endVideoPlayed) {
        this.playerOptions.sources = [{
          type: "video/mp4",
          src: 'http://ricoh-oss.oss-cn-shenzhen.aliyuncs.com/ricoh/template/video_end.mp4'
        }]
        this.playerOptions.poster = ''
        player.controlBar.hide()
        this.playerOptions.autoplay = true
        this.endVideoPlayed = true
      } else {
        this.showPlayEndedImage = false
        this.playerOptions.sources = [{
          type: "video/mp4",
          src: this.videoLink
        }]
        this.playerOptions.poster = this.poster
        this.playerOptions.autoplay = false
        this.endVideoPlayed = false
      }
    },
    onPlayerLoadedData() {},
    onPlayerReady() {},
    onClick() {},
    onFullscreenChange() {},
    onError(player) {
      console.log('视频播放出错: ', player)
      // this.$refs.player.errorDisplay.close()
      // player.error("后台转码中, 请稍后再试")
      const message = '后台转码中, 请稍后再试'

      if (player.error_.message === message) {

      } else {
        player.error(null)
        player.error(message)
      }
      // player.error_.message = '后台转码中, 请稍后再试'
    },
    onClickEndedImage() {
      if (this.$refs.player) this.$refs.player.player.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.video-player-wrapper {
  position: relative;

  .video-player {
    height: 100%;

    /deep/ .video-js {
      background-color: unset !important;
		height: 100%;

      .vjs-big-play-button {
        width: 50px;
        height: 50px;
        left: calc(50% - 25px) !important;
        top: calc(50% - 25px) !important;
        border-radius: 50%;
        border: none;

        .vjs-icon-placeholder {
          line-height: 50px;

          &::after {
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: relative;
            top: unset;
            left: unset;
            right: unset;
          }
        }
      }
    }
  }
  .player-end-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: auto;
    }
    .player-end-content {
      height: 100%;
      // background-image: url("~@/assets/images/video-end.png");
      background-image: url("~@/assets/images/video-end-first.png");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      background-color: black;
      cursor: pointer;
    }
  }
}
.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
}
</style>
